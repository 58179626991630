import methods from "../Service";
import useHttpParamsJsonCreate from "hooks/useHttpParamsJsonCreate";
const useParamsJson = (param) => { return useHttpParamsJsonCreate(param); }


const Retencion = {
    async Get() { return await methods.Get("Retencion/Get") },
    async GetNoAuth() { return await methods.Get("Retencion/GetNoAuth") },
    async Post(data) { return await methods.Post("Retencion/Post", data) },
    async Put(qs, data) { return await methods.Put("Retencion/Put" + useParamsJson(qs), data) },
    async Delete(qs) { return await methods.Delete("Retencion/Delete" + useParamsJson(qs)) },
    async Getbysecuencial(qs) { return await methods.Get("Retencion/Getbysecuencial" + useParamsJson(qs)) },

    async Anular(qs) { return await methods.Delete("Retencion/Anular" + useParamsJson(qs)) },
    async SendEmails(qs) { return await methods.Get("Comprobante/SendEmail" + useParamsJson(qs)) },
    async Post332(qs) { return await methods.Get("Retencion/Post332" + useParamsJson(qs)) },

















    async recargaPage() {
        //window.location.reload(false);
        if (localStorage.getItem('RetencionDetalle') !== undefined && localStorage.getItem('RetencionDetalle')) {
            localStorage.removeItem('RetencionDetalle');
        }
        if (localStorage.getItem('FacturaDetalle') !== undefined && localStorage.getItem('FacturaDetalle')) {
            localStorage.removeItem('FacturaDetalle');
        }
        if (localStorage.getItem('InfoPago') !== undefined && localStorage.getItem('InfoPago')) {
            localStorage.removeItem('InfoPago');
        }
        if (localStorage.getItem('idComprobanteDocumento') !== undefined && localStorage.getItem('idComprobanteDocumento')) {
            localStorage.removeItem('idComprobanteDocumento');
        }
        if (localStorage.getItem('ReembolsoDetalle') !== undefined && localStorage.getItem('ReembolsoDetalle')) {
            localStorage.removeItem('ReembolsoDetalle');
        }
        if (localStorage.getItem('FormaPago') !== undefined && localStorage.getItem('FormaPago')) {
            localStorage.removeItem('FormaPago');
        }

    }

};

export default Retencion;

