// assets

import CreditScoreIcon from '@mui/icons-material/CreditScore';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
// constant


// ==============================|| UTILITIES MENU ITEMS ||============================== //

const liquidacion = {
    id: 'comprobanteSolicitudLiquidacion',   
    type: 'group',
    children: [    
      
        {
            id: 'liquidacionCompra',
            title: 'Liquidación Compras',
            type: 'collapse',
            icon: ReceiptLongIcon,

            children: [              
                {
                    id: 'listaLiquidacion',
                    title: 'Lista de Liquidación Compra',
                    type: 'item',
                    url: '/LiquidacionCompra/ListaLiquidacion',
                    breadcrumbs: false
                },
                {
                    id: 'listaNoAutorizadaLiquidacion',
                    title: 'Lista Liquidaciones no autorizadas',
                    type: 'item',
                    url: '/LiquidacionCompra/ListaNoAutorizada',
                    breadcrumbs: false
                },
                {
                    id: 'listaSolicitudAnulacionLiquidacion',
                    title: 'Solicitudes para Anulación',
                    type: 'item',
                    url: '/LiquidacionCompra/ListaSolicitudAnulacion',
                    breadcrumbs: false
                },
                {
                    id: 'listaAutorizadasLiquidacion',
                    title: 'Autorizadas para anulación',
                    type: 'item',
                    url: '/LiquidacionCompra/ListaAutorizadaAnulacion',
                    breadcrumbs: false
                },
                {
                    id: 'listaAnuladasLiquidacion',
                    title: 'Lista Anuladas',
                    type: 'item',
                    url: '/LiquidacionCompra/ListaAnuladas',
                    breadcrumbs: false
                },
                
             
            ]
        }
    ]
};

export default liquidacion;
