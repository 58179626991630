import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

const Gasto_SIA = Loadable(lazy(() => import('component/SIA/Gasto_SIA')));

const Pago_SIA = Loadable(lazy(() => import('component/SIA/Pago_SIA')));
const Contabilizado = Loadable(lazy(() => import('component/SIA/Contabilizado_SIA')));
const PorContabilizar = Loadable(lazy(() => import('component/SIA/PorContabilizar_SIA')));

// sample page routing
const SamplePage = Loadable(lazy(() => import('views/sample-page')));

// ==============================|| MAIN ROUTING ||============================== //

const SIARoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/SIA/Pago_SIA',
            element: <Pago_SIA />
        },
        {
            path: '/SIA/Gasto_SIA',
            element: <Gasto_SIA />
        },
        {
            path: '/SIA/Contabilizado_SIA',
            element: <Contabilizado />
        },
        {
            path: '/SIA/PorContabilizar_SIA',
            element: <PorContabilizar />
        }
    ]
};

export default SIARoutes;
