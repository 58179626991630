// AuthContext.js
import React, { createContext, useContext, useState } from 'react';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  // Obtener el estado de autenticación desde localStorage
  const storedIsAuthenticated = localStorage.getItem('isAuthenticated') === 'true';
  const [isAuthenticated, setAuthenticated] = useState(storedIsAuthenticated);

  const logInContext = () => {
    
    // Lógica de autenticación, puede ser una llamada a una API o manejo de tokens
    setAuthenticated(true);
    // Guardar el estado de autenticación en localStorage
    localStorage.setItem('isAuthenticated', 'true');
  };


  const logOutContext = () => {
    // Lógica de cierre de sesión, como limpiar tokens o redirigir a la página de inicio de sesión
    setAuthenticated(false);
    // Eliminar el estado de autenticación de localStorage
    localStorage.removeItem('isAuthenticated');
  };


  return (
    <AuthContext.Provider value={{ isAuthenticated, logInContext, logOutContext }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
