import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Box, ButtonBase, Typography } from '@mui/material';

// project imports
import LogoSection from '../LogoSection';
import ProfileSection from './ProfileSection';
import NotificationSection from './NotificationSection';
// assets
import { IconMenu2 } from '@tabler/icons';
import { Fragment } from 'react';
import Periodo from 'services/Periodo/Periodo';
import { useState } from 'react';
import NotificacionToService from 'services/NotificacionTo/NotificacionToService';
import signalRService from 'services/SignalR/SignalRService';
import { useNotification } from 'Contextos/NotificationContext';

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = ({ handleLeftDrawerToggle }) => {
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);
    const ambiente = JSON.parse(localStorage.getItem('ambiente'));
    const nombreSucursal = JSON.parse(localStorage.getItem('nombreSucursal'));
    const rolTipo = JSON.parse(localStorage.getItem('nombreRolTipo'));
    const usuario = JSON.parse(localStorage.getItem('user'));
    const [listaPeriodo, setListaPeriodo] = useState([])

    const { notifications, updateNotifications } = useNotification();


    useEffect(() => {
        let isMounted = true;
        Periodo.Get()
            .then(async (result) => {
                if (isMounted) {
                    if (result.code === '1') {
                        setListaPeriodo(result.payload ? JSON.parse(result.payload) : []);
                        // periodoActivo();
                        return;
                    } else {
                        setListaPeriodo([]);
                    }
                }
            })
            .catch((e) => {
                console.log(e.message);
            });
        return () => {
            isMounted = false;
        };
    }, []);

    // useEffect(() => {

    //     NotificacionToService.GetAll()
    //         .then(async (result) => {
    //             if (result.code === '1') {
    //                 updateNotifications(result.payload ? JSON.parse(result.payload) : []);
    //                 return
    //             }
    //             else {
    //                 updateNotifications([])
    //             }

    //             // console.log(result.message);
    //         })
    //         .catch((e) => {

    //             console.log(e.message);
    //         });
    // }, [])

    // useEffect(() => {
    //     const connectionHub = signalRService.connection;
    //     connectionHub.on("RespuestaActualizaNotificacion", function () {
    //         setLoad(!load);

    //     });
    // }, [])


    return (
        <Fragment>
            {/* logo & toggler button */}
            <Box
                sx={{
                    width: 228,
                    display: 'flex',
                    [theme.breakpoints.down('md')]: {
                        width: 'auto'
                    }
                }}
            >
                <Box component="span" sx={{ display: { xs: 'none', md: 'block' }, flexGrow: 1 }}>
                    <LogoSection />
                </Box>
                <ButtonBase sx={{ borderRadius: '12px', overflow: 'hidden' }}>
                    <Avatar
                        variant="rounded"
                        sx={{
                            ...theme.typography.commonAvatar,
                            ...theme.typography.mediumAvatar,
                            transition: 'all .2s ease-in-out',
                            background: theme.palette.secondary.light,
                            color: theme.palette.secondary.dark,
                            '&:hover': {
                                background: theme.palette.secondary.dark,
                                color: theme.palette.secondary.light
                            }
                        }}
                        onClick={handleLeftDrawerToggle}
                        color="inherit"
                    >
                        <IconMenu2 stroke={1.5} size="1.3rem" />
                    </Avatar>
                </ButtonBase>
            </Box>

            {/* header search */}
            {/* <SearchSection /> */}
            <Box></Box>
            <Box
                sx={{
                    flexGrow: 1
                }}
                marginLeft="15px"
            >
                <div>
                    <Typography
                        variant="caption text"
                        gutterBottom
                        style={{ float: 'left' }}
                        sx={{
                            display: { xs: 'none', md: 'block' },
                            flexGrow: 1
                        }}
                    >
                        <small>
                            Bienvenido {'(a)'}: <strong>{usuario}</strong> , accediendo como <strong>{rolTipo}</strong>
                        </small>
                        <br></br>
                        <small>
                            <strong>Sucursal: {nombreSucursal}</strong>
                        </small>
                        <br></br>
                        <small>
                            Conectado en Ambiente de: <strong>{ambiente}</strong>
                        </small>


                    </Typography>
                </div>

            </Box>
            <Box>
                <small>
                    Periodo Activo: <strong>{listaPeriodo.find((row) => row.estado === true)?.gestion + ' - ' + listaPeriodo.find((row) => row.estado === true)?.mes}</strong>
                </small>
            </Box>
            
            {/* notification & profile */}
            {/* <Button startIcon={<ShareIcon />} onClick={handleClickOpen('paper')}></Button> */}

            {/* <NotificationSection listaNotificacion={notifications} /> */}
            <ProfileSection />

            {/* <ModalQr open={open} onClose={handleClose} /> */}
        </Fragment>
    );
};

Header.propTypes = {
    handleLeftDrawerToggle: PropTypes.func
};

export default Header;
