import { Link } from 'react-router-dom';

// material-ui
import { Avatar, ButtonBase, Typography } from '@mui/material';

// project imports
import config from 'config';
//import Logo from 'ui-component/Logo';
import imgs from '../../../assets/images/logoCompras.png'
import { Box } from '@mui/system';

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => (
    
    // to={config.defaultPath}> component={Link} to={''}
    // <ButtonBase disableRipple  >
    //     <Typography variant="h2" gutterBottom component="div" color="#01579b">
    //         Sistema Gestión de Proyectos
    //     </Typography>

    // </ButtonBase>
    <>
        <Avatar src={imgs} sx={{ minWidth: 125, bgcolor: 'secondary.main' }} variant="square" >

        </Avatar>

    </>
);

export default LogoSection;
