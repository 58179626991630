import ReactDOM from 'react-dom';

// third party
import { HashRouter } from 'react-router-dom';
import { Provider } from 'react-redux';

// project imports
import * as serviceWorker from 'serviceWorker';

import App from 'App';
import { store } from 'store';

// style + assets
import 'assets/scss/style.scss';
import { AuthProvider } from 'Contextos/AuthContext';
import { NotificationProvider } from 'Contextos/NotificationContext';



// ==============================|| REACT DOM RENDER  ||============================== //


ReactDOM.render(
    
    <Provider store={store}>
        <AuthProvider>
            <NotificationProvider>
                <HashRouter>
                    <App />
                </HashRouter>
            </NotificationProvider>
        </AuthProvider>
    </Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
