// signalrService.js
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';

class SignalRService {
  connection;

  startConnection = () => {
    this.connection = new HubConnectionBuilder()
      .withUrl(process.env.REACT_APP_HOST_SGP_SIGNAL + 'ComprasHub', {
        withCredentials: true,  // Importante para incluir credenciales en la solicitud CORS
      })
      .configureLogging(LogLevel.Information)
      .build();

    return this.connection.start();
  };

  stopConnection = () => {
    return this.connection.stop();
  };
}

const signalRService = new SignalRService();
export default signalRService;
