import { useSelector } from 'react-redux';

import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';

// routing
import Routes from 'routes';

// defaultTheme
import themes from 'themes';

// project imports
import NavigationScroll from 'layout/NavigationScroll';
import { ToastContainer } from 'material-react-toastify';
import LogIn from 'views/pages/authentication/authentication3/Login3';
import { useEffect, useState } from 'react';
import { useAuth } from 'Contextos/AuthContext';
import signalRService from 'services/SignalR/SignalRService';
import { warningNotification } from 'utils/toastify';
import { useNotification } from 'Contextos/NotificationContext';
import NotificacionToService from 'services/NotificacionTo/NotificacionToService';
import { LoadingProvider } from 'Contextos/LoadingContext';
import ButtonLoading from 'Contextos/ButtonLoading';
// ==============================|| APP ||============================== //

const App = () => {

    const { isAuthenticated, logInContext, logOutContext } = useAuth();
    const [signalRConnection, setSignalRConnection] = useState(false);
    const { addNotification, updateNotifications } = useNotification();
    // const [isAuthenticated, setIsAuthenticated] = useState(false); // Estado de autenticación
    const [goLogin, setGoLogin] = useState(false); // Variable para renderizar el componente Login
    // const go = localStorage.getItem("go");

    // const handleRecconect = () => {
    //     signalRService.startConnection()
    //         .then(() => {
    //             console.log('Conexión establecida');
    //             setSignalRConnection(true);

    //         })
    //         .catch(err => console.error('Error de conexión:', err));
    // }

    // useEffect(() => {

    //     if (isAuthenticated) {


    //         signalRService.startConnection()
    //             .then(() => {
    //                 console.log('Conexión establecida');
    //                 setSignalRConnection(true);

    //             })
    //             .catch(err => console.error('Error de conexión:', err));

    //         // Manejar la desconexión al descargar el componente
    //         return () => {
    //             signalRService.stopConnection()
    //                 .then(() => {

    //                     console.log('Conexión cerrada');
    //                     setSignalRConnection(false);
    //                 })
    //                 .catch(err => console.error('Error al cerrar la conexión:', err));
    //         };
    //     }


    // }, [isAuthenticated]);



    // useEffect(() => {


    //     if (signalRConnection) {


    //         const connectionHub = signalRService.connection;

    //         var thisUser = {};
    //         var allUsers = [];

    //         thisUser = {
    //             idUsuario: localStorage.getItem("idUsuario"),
    //             codigoRolTipo: localStorage.getItem("codigoRolTipo"),
    //             user: localStorage.getItem("user"),
    //         }

    //         if (thisUser.idUsuario == undefined || thisUser.idUsuario == 'null') {
    //             return
    //         }

    //         const datosJson = JSON.stringify(thisUser);

    //         connectionHub.invoke("Connect", datosJson)
    //             .catch(function (err) {

    //                 console.error("Error invoking ", err);
    //             });


    //         connectionHub.on("UpdateUsers", function (cantidad, usuarioNuevo, usuariosTodos) {
    //             allUsers = usuariosTodos;

    //             if (usuarioNuevo.idUsuario == thisUser.idUsuario) {
    //                 thisUser = {
    //                     ...thisUser,
    //                     conectionId: usuarioNuevo.conectionId
    //                 }

    //                 localStorage.setItem("conectionId", usuariosTodos.filter(x => x.idUsuario == thisUser.idUsuario)[0].conectionId)
    //                 //thisUser.conectionId = usuarioNuevo.idRol.conectionId;
    //             };
    //             console.log(usuarioNuevo.user + " está conectado.");
    //             warningNotification(usuarioNuevo.user + " está conectado.");


    //         });
    //     }
    // }, [signalRConnection])

    // useEffect(() => {
    //     if (signalRConnection) {

    //         const connectionHub = signalRService.connection;
    //         connectionHub.on("RespuestaSolicitudAnulacion", function (message, data) {

    //             warningNotification(message);
    //             NotificacionToService.ActualizarNotificacion(data, addNotification);
    //             //ActualizaNotificacion(data)

    //         });

    //         connectionHub.onclose(() => {
    //             setTimeout(handleRecconect, 5000);
    //         })
    //     }
    // }, [signalRConnection])



    const customization = useSelector((state) => state.customization);

    const ActualizaNotificacion = (data) => {
        const { idNotificacionTo, idNotificacion, idEntregaTipo, messageTitle, message, nameSucursal, codigoEstado } = JSON.parse(data)
        const newNotification = {
            idNotificacionTo: idNotificacionTo
            , idNotificacion: idNotificacion
            , idEntregaTipo: idEntregaTipo
            , messageTitle: messageTitle
            , message: message
            , nameSucursal: nameSucursal
            , codigoEstado: codigoEstado
        };
        addNotification(newNotification);

    }

    // setGoLogin(go);

    // if (go === true || go === "true" || go === null || go === undefined) {
    //     // localStorage.clear();

    //     return (<LogIn />);
    // }

    // if (window.localStorage) {
    //     window.addEventListener('go', event => {

    //         if (event.storageArea === localStorage) {
    //             if (window.localStorage.getItem('go') !== undefined
    //                 && window.localStorage.getItem('go')
    //             ) {

    //                 setGoLogin(false)

    //             }
    //         }
    //     }, false);
    // }



    // useEffect(() => {
    //     setGoLogin(!isAuthenticated);
    // }, [isAuthenticated]);

    return (
        <>

            {/* <StyledEngineProvider injectFirst>
                <ThemeProvider theme={themes(customization)}>
                    <CssBaseline />
                    {goLogin ? (
                        <LogIn onLogin={() => setIsAuthenticated(true)} />
                    ) : (
                        <Routes onLogout={() => setIsAuthenticated(false)} />
                    )}
                </ThemeProvider>
            </StyledEngineProvider> */}

            <StyledEngineProvider injectFirst>

                <ThemeProvider theme={themes(customization)}>
                    {/* <LoadingProvider> */}
                        <ToastContainer />
                        <CssBaseline />
                        {!isAuthenticated ? (
                            console.log("Usuario no autenticado"),
                            <LogIn />
                        ) : (
                            console.log("Usuario autenticado"),
                            <Routes />
                        )}
                        {/* <ButtonLoading />
                    </LoadingProvider> */}
                </ThemeProvider>

            </StyledEngineProvider >


        </>
    );
};

export default App;
