// assets

import SettingsIcon from '@mui/icons-material/Settings';

import DescriptionIcon from '@mui/icons-material/Description';
import DateRangeOutlinedIcon from '@mui/icons-material/DateRangeOutlined';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
// constant

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const config = {
    id: 'config',
    type: 'group',
    children: [
        {
            id: 'config2',
            title: 'Configuración',
            type: 'collapse',
            icon: SettingsIcon,

            children: [
                {
                    id: 'aperturaPeriodo',
                    title: 'Apertura Periodo',
                    type: 'item',
                    url: '/Configuracion/AperturaPeriodo',
                    breadcrumbs: false
                    // icon: DateRangeOutlinedIcon
                },
                {
                    id: 'ambiente',
                    title: 'Ambiente',
                    type: 'item',
                    url: '/Configuracion/Ambiente',
                    breadcrumbs: false
                },

                {
                    id: 'usuarios',
                    title: 'Usuarios',
                    type: 'item',
                    url: '/Configuracion/Usuario',
                    breadcrumbs: false
                    // icon :ManageAccountsOutlinedIcon
                },
                // {
                //     id: 'solicitudesUsuarios',
                //     title: 'Solicitudes de Usuarios',
                //     type: 'item',
                //     url: '/Configuracion/SolicitudUsuario',
                //     breadcrumbs: false
                // },
                // {
                //     id: 'talonarioFisico',
                //     title: 'Reg Talonarios Físicos',
                //     type: 'item',
                //     url: '/Configuracion/TalonarioFisico',
                //     breadcrumbs: false
                // },
                {
                    id: 'puntoEmision',
                    title: 'Punto Emisión',
                    type: 'item',
                    url: '/Configuracion/PuntoEmision',
                    breadcrumbs: false
                },
                {
                    id: 'secuencialElectronica',
                    title: 'Secuencial Electrónica',
                    type: 'item',
                    url: '/Configuracion/SecuencialElectronica',
                    breadcrumbs: false
                },
                // {
                //     id: 'authLiqui',
                //     title: 'Reg Autorización Liqui',
                //     type: 'item',
                //     url: '/Configuracion/RegistroLiquidacion',
                //     breadcrumbs: false
                // },
                // {
                //     id: 'anulacionComprobantesFisicos',
                //     title: 'Reg Anulacion de comprobantes fisicos',
                //     type: 'item',
                //     url: '/Configuracion/RegAnulacionComprobanteFisico',
                //     breadcrumbs: false
                // },
                {
                    id: 'configCuenta',
                    title: 'Configuración de cuentas',
                    type: 'item',
                    url: '/Configuracion/ConfigCuenta',
                    breadcrumbs: false
                },

                {
                    id: 'configProveedor',
                    title: 'Configuración Proveedor',
                    type: 'collapse',
                    //icon: SettingsIcon,
                    breadcrumbs: false,
                    children: [
                        {
                            id: 'configRegimenFiscalExteriorTipo',
                            title: 'Configuración Regimen Fiscal Exterior',
                            type: 'item',
                            url: '/Configuracion/RegimenFiscalExterior',
                            breadcrumbs: false
                        },
                        {
                            id: 'configPais',
                            title: 'Configuración País',
                            type: 'item',
                            url: '/Configuracion/Pais',
                            breadcrumbs: false
                        },
                        {
                            id: 'configProveedorTipo',
                            title: 'Configuración Proveedor Tipo',
                            type: 'item',
                            url: '/Configuracion/ProveedorTipo',
                            breadcrumbs: false
                        },
                        {
                            id: 'configIdentificacionTipo',
                            title: 'Configuración Identificación Tipo',
                            type: 'item',
                            url: '/Configuracion/IdentificacionTipo',
                            breadcrumbs: false
                        },
                        {
                            id: 'configImpuestoTarifa',
                            title: 'Configuración Impuesto Tarifa',
                            type: 'item',
                            url: '/Configuracion/ImpuestoTarifa',
                            breadcrumbs: false
                        }
                    ]
                }
            ]
        }
    ]
};

export default config;
