import React from 'react';
import { Typography } from '@mui/material';
// project imports
import NavCollapse from './NavCollapse';
import NavItem from './NavItem';

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = ({ menuList }) => {
    console.log('invocando componente MenuList');
    

    //console.log("Cantidad de menus: ",menuItem.length);
    
    const navItems = menuList?.map((menu) => {
    
        switch (menu?.type) {
            case 'collapse':
                return <NavCollapse key={menu?.id} menu={menu} level={1} />;
            case 'item':
                return <NavItem key={menu?.id} item={menu} level={1} />;
            default:
                return (
                    <Typography key={menu?.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    return <>{navItems}</>;
};

export default MenuList;
