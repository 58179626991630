// assets

import CreditScoreIcon from '@mui/icons-material/CreditScore';
import RequestPageIcon from '@mui/icons-material/RequestPage';
// constant

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const retencion = {
    id: 'comprobanteSolicitudRetencion',
    type: 'group',
    children: [
        {
            id: 'retencion',
            title: 'Retenciones',
            type: 'collapse',
            icon: RequestPageIcon,

            children: [
                // {
                //     id: 'anulacionReten',
                //     title: 'Anulacion de Retenciones',
                //     type: 'item',
                //     url: '/Retencion/AnulacionRetencion',
                //     breadcrumbs: false
                // },
                // {
                //     id: 'retenciones',
                //     title: 'Retenciones',
                //     type: 'item',
                //     url: '/Retencion/Retenciones',
                //     breadcrumbs: false
                // },
                // {
                //     id: 'retenNoAut',
                //     title: 'Retenciones no Autorizadas',
                //     type: 'item',
                //     url: '/Retencion/RetencionNoAut',
                //     breadcrumbs: false
                // },
                {
                    id: 'listaRetencion',
                    title: 'Lista de Retenciones',
                    type: 'item',
                    url: '/ComprobanteSolicitudAnulacion/ListaRetencion',
                    breadcrumbs: false
                },
                {
                    id: 'listaNoAutorizadaRetencion',
                    title: 'Retenciones no Autorizadas',
                    type: 'item',
                    url: '/Retencion/ListaNoAutorizada',
                    breadcrumbs: false
                },

                {
                    id: 'listaSolicitudAnulacionRetencion',
                    title: 'Solicitudes para Anulación',
                    type: 'item',
                    url: '/ComprobanteSolicitudAnulacion/ListaSolicitudAnulacion',
                    breadcrumbs: false
                },
                {
                    id: 'listaAutorizadasRetencion',
                    title: 'Autorizadas para anulación',
                    type: 'item',
                    url: '/ComprobanteSolicitudAnulacion/ListaAutorizadaAnulacion',
                    breadcrumbs: false
                },
                {
                    id: 'listaAnuladasRetencion',
                    title: 'Lista Anuladas',
                    type: 'item',
                    url: '/ComprobanteSolicitudAnulacion/ListaAnuladas',
                    breadcrumbs: false
                }
            ]
        }
    ]
};

export default retencion;
