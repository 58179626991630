const URL = process.env.REACT_APP_HOST_API_SGP;

const METHODS = {
    GET: 'GET',
    POST: 'POST',
    PUT: 'PUT',
    DELETE: 'DELETE'
};

const CONFIG_REQUEST = (METHOD, TOKEN, PAYLOAD = '') => {
    return {
        method: METHOD, // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json charset=utf-8',
            'Authorization': TOKEN
        },
        //redirect: 'follow', // manual, *follow, error
        //referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: PAYLOAD // body data type must match "Content-Type" header
    };
};

const methods = {
    async Get(url) {
        const JWT = JSON.parse(localStorage.getItem('JWT'));

        const config = CONFIG_REQUEST(METHODS.GET, JWT);
        delete config.body;

        const response = await fetch(URL + url, config);

        const result = await response.json();
        // console.log("RESPONDIO SOLICITUD: " + URL + url, result,)


        return result;
    },

    async Post(url, data) {
        const JWT = JSON.parse(localStorage.getItem('JWT'));
        const config = CONFIG_REQUEST(METHODS.POST, JWT, JSON.stringify(data));
        const response = await fetch(URL + url, config);
        const result = await response.json();
        return result;
    },

    async Put(url, data) {
        const JWT = JSON.parse(localStorage.getItem('JWT'));
        const config = CONFIG_REQUEST(METHODS.PUT, JWT, JSON.stringify(data));
        const response = await fetch(URL + url, config);
        const result = await response.json();
        return result;
    },

    async Delete(url, id) {
        const JWT = JSON.parse(localStorage.getItem('JWT'));
        const config = CONFIG_REQUEST(METHODS.DELETE, JWT);
        delete config.body;
        const response = await fetch(URL + url, config);
        const result = await response.json();
        return result;
    }
};

export default methods;
