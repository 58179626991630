// assets

import CreditScoreIcon from '@mui/icons-material/CreditScore';
// constant
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';


// ==============================|| UTILITIES MENU ITEMS ||============================== //

const reporte = {
    id: 'Reporte',
    type: 'group',
    children: [    
       
      
        {
            id: 'compr',
            title: 'Reportes',
            type: 'collapse',
            icon: PictureAsPdfIcon,

            children: [
                // {
                //     id: 'listadoGeneralComprobante',
                //     title: 'Listado General Comprobantes',
                //     type: 'item',
                //     url: '/Reportes/ListadoGeneralComprobante',
                //     breadcrumbs: false
                // },                              
                // {
                //     id: 'listadoGenral',
                //     title: 'Lista General Retenciones',
                //     type: 'item',
                //     url: '/Retencion/listadoGeneral',
                //     breadcrumbs: false
                // },
                {
                    id: 'listadoXParametro',
                    title: 'Lista Retenciones por Sucursal',
                    type: 'item',
                    url: '/Retencion/listadoXParametro',
                    breadcrumbs: false
                },
                {
                    id: 'listaGeneralCompra',
                    title: 'Listado General de Compras',
                    type: 'item',
                    url: '/Reportes/ListadoGeneralCompra',
                    breadcrumbs: false
                },
                {
                    id: 'consolidadoCompra',
                    title: 'Consolidado de compras',
                    type: 'item',
                    url: '/Reportes/ConsolidadoCompra',
                    breadcrumbs: false
                },
                {
                    id: 'consolidadoCompraDesglosado',
                    title: 'Consolidado de compras Desglosado',
                    type: 'item',
                    url: '/Reportes/ConsolidadoCompraDesglosado',
                    breadcrumbs: false
                },
                {
                    id: 'listadoProveedorEstablecimiento',
                    title: 'Listado Proveedores',
                    type: 'item',
                    url: '/Reportes/ListadoProveedoresEstablecimiento',
                    breadcrumbs: false
                },
                {
                    id: 'listadoPersonaLiquidacion',
                    title: 'Listado Persona Liquidación',
                    type: 'item',
                    url: '/Reportes/ListadoPersonaLiquidacion',
                    breadcrumbs: false
                },
                
             
            ]
        },
    ]
};

export default reporte;
