import dashboard from './dashboard';
import pages from './pages';
import utilities from './departamento';
import proveedor from './proveedor';
import configuracion from './configuracion';
import retencion from './retencion';
import comprobante from './comprobante';
import reporte from './reporte';
import proyecto from './proyecto';
import contabilizacion from './contabilizacion';
import liquidacion from './liquidacionCompra';
import documento from './documento';
import administracionRol from './administracionRol';
import Contabilizacion_AASSINet from './contabilizacion_EnlaceAASINet';
import Enlace_SIA from './enlace_SIA';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [
        dashboard,
        configuracion,
        utilities,
        proveedor,
        documento,
        liquidacion,
        retencion,
        comprobante,
        reporte,    
        // contabilizacion,   
        Contabilizacion_AASSINet,
        Enlace_SIA
    ]
};

export default menuItems;
