// ButtonLoading.js
import React, { useContext } from 'react';
import { CircularProgress, makeStyles } from '@material-ui/core';
import { LoadingContext } from './LoadingContext'; // Asegúrate de crear este contexto
import { Box, Button } from '@mui/material';
import { green } from '@mui/material/colors';

const useStyles = makeStyles((theme) => ({
    button: {
        position: 'fixed',
        top: '50%',
        right: theme.spacing(2), // Ajusta el espacio a tu preferencia
        transform: 'translateY(-50%)',
        zIndex: 1000,
    },
}));

const ButtonLoading = () => {
    const classes = useStyles();
    const { loading, setLoading, name, setName } = useContext(LoadingContext);

    const handleButtonClick = () => {
        // Aquí puedes hacer lo que necesites antes de cambiar el estado de carga
        setLoading(!loading);
    };
    const buttonSx = {
        ...({
            bgcolor: green[500],
            '&:hover': {
                bgcolor: green[700],
            },
        }),
    };

    return (<>
        {loading &&
            <Box sx={{ m: 1, position: 'relative' }}>
                <Button
                    variant="contained"
                    className={classes.button}
                    // sx={buttonSx}
                    disabled={loading}
                    color='warning'

                >
                    {name}
                </Button>
                {loading && (
                    <CircularProgress
                        size={24}
                        color="inherit"                    
                    />
                )}
            </Box>
            }


        {/* <Button
            variant="contained"
            color="primary"
            // sx={{
            //     position: "fixed", zIndex: 1000, bottom: theme.spacing(2),
            //     right: theme.spacing(2)
            // }}
            className={classes.button}

            onClick={handleButtonClick}
        >
            {loading ? <CircularProgress size={24} color="inherit" /> : name}
        </Button> */}

    </>
    );
};

export default ButtonLoading;
