import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Navigate } from 'react-router';
import imgs from '../../../../assets/images/logoCompras.png';
import login from '../../../../services/Login/LoginService';
//import SweetAlert from 'utils/SweetAlert/SweetAlert';
import { errorNotification, saveNotification } from 'utils/toastify';
import { ToastContainer } from 'material-react-toastify';
import portada from '../../../../assets/images/portadaLogin.jpg';

import { LoadingButton } from '@mui/lab';
import { useAuth } from 'Contextos/AuthContext';
import { FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="#">
                Corporación Adventista
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const theme = createTheme();

export default function LogIn(props) {
    const [open, setOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const { logInContext } = useAuth();

    const handleSubmit = (event) => {

        event.preventDefault();


        localStorage.setItem('go', true);

        const data = new FormData(event.currentTarget);

        const dataSend = {
            codigoUsuario: data.get('codigoUsuario'),
            contrasena: data.get('contrasena')
        };
        setLoading(true);

        login
            .Post(dataSend)
            .then(async (result) => {


                if (result.code === '1') {
                    const resp = result.payload;
                    localStorage.setItem('roles', JSON.stringify(resp.roles));
                    localStorage.setItem('go', false);
                    localStorage.setItem('JWT', JSON.stringify('Bearer ' + resp.token));
                    // console.log("token login despues de local : "+resp.token);
                    localStorage.setItem('codigoUsuario', JSON.stringify(data.get('codigoUsuario')));
                    localStorage.setItem('user', JSON.stringify(resp.user));
                    localStorage.setItem('idSucursal', JSON.stringify(resp.idSucursal));
                    localStorage.setItem('nombreSucursal', JSON.stringify(resp.nombreSucursal));

                    localStorage.setItem('nombreRolTipo', JSON.stringify(resp.nombreRol));
                    localStorage.setItem('codigoRolTipo', JSON.stringify(resp.codigoRolTipo));

                    localStorage.setItem('ambiente', JSON.stringify(resp.ambiente));
                    localStorage.setItem('idRol', JSON.stringify(resp.idRol));
                    localStorage.setItem('idUsuario', JSON.stringify(resp.idUsuario));
                    // localStorage.setItem('accesoConfiguracionAccount', resp.accesoConfiguracionAccount);
                    // localStorage.setItem('accesoConfiguracionRetencion', resp.accesoConfiguracionRetencion);




                    localStorage.setItem('hour', JSON.stringify(3));
                    localStorage.setItem('minute', JSON.stringify(54));
                    localStorage.setItem('second', JSON.stringify(59));
                    localStorage.setItem('isAuthenticated', 'true');

                    setLoading(false);

                    startSesion(result.redirectTo, login.verifyLogin());

                } else {
                    setLoading(false);

                    errorNotification(result.message);
                }
            })
            .catch((e) => {
                // SweetAlert.Warning();
                errorNotification('El servicio no esta funcionando');
                console.log('error server');
                setLoading(false);

            });


        const startSesion = (ruta, valor) => {
            if (!valor) {
                logInContext();

                window.location.href = ruta;
                // window.location.reload();
                saveNotification('Acceso correcto');
            }
        };
    };
    const [showPassword, setShowPassword] = React.useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    return (
        <ThemeProvider theme={theme}>
            <Grid container component="main" sx={{ height: '100vh' }}>
                <ToastContainer />
                <CssBaseline />

                <Grid
                    item
                    xs={false}
                    sm={4}
                    md={7}
                    //https://source.unsplash.com/random
                    // style={{ backgroundImage: `url(${background})` }}
                    sx={{
                        backgroundImage: `url(${portada})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundColor: (t) => (t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900]),
                        backgroundSize: 'cover',
                        backgroundPosition: 'center'
                    }}
                />

                <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                    <Box
                        sx={{
                            my: 8,
                            mx: 4,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center'
                        }}
                    >
                        {/* src={imgs}  variant="square"*/}
                        <Avatar src={imgs} variant="square" sx={{ m: 1, minWidth: 125, bgcolor: 'secondary.main' }}></Avatar>
                        <Typography component="h1" variant="h5">
                            Iniciar Sesión
                        </Typography>
                        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="codigoUsuario"
                                label="Usuario"
                                name="codigoUsuario"
                                autoComplete="codigoUsuario"
                                autoFocus
                            />
                            <FormControl sx={{ width: '100%' }} variant="outlined">
                                <InputLabel htmlFor="outlined-adornment-password">Contraseña</InputLabel>
                                <OutlinedInput
                                    id="contrasena"
                                    name="contrasena"
                                    type={showPassword ? 'text' : 'password'}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    label="Contraseña"
                                />
                            </FormControl>
                            {/* <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="contrasena"
                                label="Contraseña"
                                type="password"
                                id="contrasena"
                                autoComplete="current-password"
                            /> */}
                            <LoadingButton
                                type="submit"
                                sx={{ mt: 3, mb: 2, width: '100%' }}
                                loading={loading}
                                loadingIndicator="Ingresando…"
                                variant="contained"
                            >
                                <span>Iniciar Sesión</span>
                            </LoadingButton>
                            <Grid container>
                                <Grid item>
                                </Grid>
                            </Grid>
                            <Copyright sx={{ mt: 5 }} />
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </ThemeProvider>
    );
}
