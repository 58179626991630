import React, { createContext, useContext, useState } from 'react';

const NotificationContext = createContext();

export const NotificationProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([]);
  
console.log("Invocando NotificationProvider")

  // setInterval(() => {
  //   console.log({notificationsTest});
  // }, 1000);

  const addNotification = (newNotification) => {

    debugger
    console.log({ notifications })
    console.log({ newNotification })

    setNotifications([...notifications, ...newNotification]);
  };

  // const removeNotification = (id) => {
  //   const updatedNotifications = notifications.filter((notification) => notification.id !== id);
  //   setNotifications(updatedNotifications);
  // };

  const updateNotifications = (newNotifications) => {
    setNotifications(newNotifications);
    
  };

  return (
    <NotificationContext.Provider value={{ notifications, addNotification, updateNotifications }}>
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotification = () => {
  return useContext(NotificationContext);
};
