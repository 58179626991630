import React, { Fragment, useEffect, useState } from "react";
import { Grid, List, ListItem, ListItemIcon, ListItemText, ListSubheader, NativeSelect, Paper, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useForm } from "react-hook-form"
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Button from "@mui/material/Button";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import ProfesionTipo from "services/Profesion/ProfesionTipoService";
import PersonaLiquidacion from "services/PersonaLiquidacion/PersonaLiquidacionService";
import useNavigateParamsSearch from "hooks/useNavigateParamsSearch";
import { BootstrapInput } from "themes/BootstrapInput";
import { Box } from "@mui/system";
import { errorNotification, errorSwalNotification, saveNotification } from "utils/toastify";
import { ToastContainer } from "material-react-toastify";
import { ButtonCloseModal, ButtonModal } from "utils/custom-all";
import Resource from "resource/resource";
import WifiIcon from '@mui/icons-material/Wifi';
import BluetoothIcon from '@mui/icons-material/Bluetooth';
import PersonIcon from '@mui/icons-material/Person';
import Usuario from "services/Usuario/UsuarioService";
const RolModal = (props) => {
    const params = useNavigateParamsSearch();
    const { register, formState: { errors }, handleSubmit, setValue, reset } = useForm();
    const [scroll, setScroll] = useState('paper');
    const [listaRol, setListaRol] = useState([]);
    const [idSucursal, setIdSucursal] = useState();
    // useEffect(() => { reset(form) }, [form]);

    useEffect(() => {
        setListaRol(JSON.parse(localStorage.getItem('roles')));
        setIdSucursal(localStorage.getItem('idSucursal'));
    }, []);

    const [checked, setChecked] = React.useState(['wifi']);

    const handleToggle = (item) => {
        console.log(item)

    };

    const Save = (data) => {
        Usuario.changePassword(data)
            .then(async (result, data) => {
                if (result.code === '1') {
                    saveNotification(result.message);
                    props.onClose(false);
                    setValue('contrasena', '');
                    setTimeout(() => {

                        props.logOut();
                    }, 3000)

                } else {
                    setValue('contrasena', '');
                    props.onClose(false);
                    errorSwalNotification(result.message);
                }
            })
            .catch((e) => {
                console.log(e.message);
            });
    };

    const onSubmit = (data) => {
        Save(data);
    };

    return (
        <Fragment>
            <Dialog
                open={props.open}
                onClose={props.onClose}
                scroll={scroll}
                fullWidth
                id="dialog"
                maxWidth="sm"
                component="form"
                onSubmit={handleSubmit(onSubmit)}
            >
                <ToastContainer />
                <DialogTitle id="scroll-dialog-title">
                    Cambiar Contraseña
                    <ButtonCloseModal cerrarModal={props.onClose} />
                </DialogTitle>
                <DialogContent dividers>
                    <Grid container spacing={2}>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <TextField
                                {...register('contrasena')}
                                id="contrasena"
                                name="contrasena"
                                label="Contraseña: "
                                style={{ width: '100%' }}
                                size="small"
                                autoComplete="off"

                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>                    
                <ButtonModal event={props.onClose} name1="Cancelar" name2="Guardar" />
                    {/* <Button type="submit"  >Aceptar</Button>
                    <Button onClick={props.onClose}>Cancelar</Button> */}
                </DialogActions>
            </Dialog>

        </Fragment >

    );
}

export default RolModal;
