// ProtectedRoute.js
import { useAuth } from 'Contextos/AuthContext';
import React from 'react';
import { Navigate } from 'react-router-dom';


const ProtectedRoute = ({ element }) => {
    const { isAuthenticated } = useAuth();

    return isAuthenticated ? element : <Navigate to="/login" />;
};

export default ProtectedRoute;
