import Button from '@mui/material/Button';
import React, { Fragment } from 'react';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import { useNavigate } from 'react-router';
import { Fab, IconButton, Input, InputAdornment, TextField, Tooltip, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import Swal from 'sweetalert2';
import Retencion from 'services/Retencion/RetencionServices';
import AnimateButton from 'ui-component/extended/AnimateButton';
import RotateRightIcon from '@mui/icons-material/RotateRight';
import { useTheme } from '@mui/material/styles';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import SearchIcon from '@mui/icons-material/Search';
import SaveIcon from '@mui/icons-material/Save';
const ButtonAdd = (props) => {
    const { name, icon, color, eventDisebled, ...other } = props;

    return (
        <Fragment>
            <Button
                disabled={eventDisebled || false}
                {...other}
                size="small"
                color={color || 'info'}
                variant="contained"
                style={{ width: '100%' }}
            >
                {icon || <AddIcon fontSize="inherit" />} <small> {name || ''} </small>
            </Button>
        </Fragment>
    );
};
const ButtonEdit = (props) => {
    const { name, icon, evtdisabled, event, ...other } = props;
    return (
        <Fragment>
            <Button
                {...other}
                disabled={false || evtdisabled}
                size="small"
                variant="contained"
                style={{ width: '100%', backgroundColor: '#ffac33' }}
            >
                <EditIcon fontSize="inherit" /> {name || ''}
            </Button>
        </Fragment>
    );
};
const ButtonDelete = (props) => {
    const { name, icon, tamano, ...other } = props;
    return (
        <Fragment>
            <Button {...other} size="small" variant="contained" style={{ width: tamano || '100%', backgroundColor: '#e91e63' }}>
                {icon || <DeleteIcon fontSize="inherit" />} {name || ''}
            </Button>
        </Fragment>
    );
};

const ButtonBaja = (props) => {
    const { name, ...other } = props;
    return (
        <Fragment>
            <Button {...other} size="small" variant="contained" style={{ width: '100%', backgroundColor: '#e91e63' }}>
                <NotInterestedIcon fontSize="inherit" /> {name || ''}
            </Button>
        </Fragment>
    );
};
const ButtonRoles = (props) => {
    const { name, ...other } = props;
    return (
        <Fragment>
            <Button {...other} size="small" variant="contained" style={{ width: '100%', backgroundColor: '#03a9f4' }}>
                <PeopleAltIcon fontSize="inherit" /> <small> {name || ''}</small>
            </Button>
        </Fragment>
    );
};

const ButtonAddDetails = (props) => {
    const { name, icon, ...other } = props;
    return (
        <Fragment>
            <Button {...other} size="small" variant="contained" style={{ width: '100%' }}>
                {icon || <AddIcon fontSize="inherit" />}
                <small> {name || ''}</small>
            </Button>
        </Fragment>
    );
};
const ButtonCancel = (props) => {
    const { name, icon, path, ...other } = props;

    const navigate = useNavigate();
    const cancelar = () => {
        Swal.fire({
            title: '¿ Esta seguro de Cancelar ?',
            text: '¡No se podrá revertir este proceso!',
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#6fbf73',
            cancelButtonColor: '#d33',
            confirmButtonText: '<small>Si</small>',
            cancelButtonText: '<small>Cancelar!</small>'
        }).then((result) => {
            if (result.isConfirmed) {
                Retencion.recargaPage();
                navigate('/documentos' || path);
            }
        });
    };
    return (
        <Fragment>
            <Button
                onClick={props.onClick || cancelar}
                {...other}
                size="small"
                variant="contained"
                style={{ width: '100%' }}
                color='error'
                startIcon={icon || ''}
            >
                <small>{name || ''}</small>
            </Button>
        </Fragment>
    );
};

const ButtonSave = (props) => {
    const { name, icon, color, size, ...other } = props;

    return (
        <Fragment>
            <Button
                {...other}
                type="submit"
                startIcon={icon || ''}
                size={size || "small"}
                variant="contained"
                color={color || 'success'}
                style={{ width: '100%' }}
            >
                {/* {icon || ''} */}
                <small>{name || ''}</small>
            </Button>
        </Fragment>
    );
};
const ButtonInfoPago = (props) => {
    const { name, icon, ...other } = props;

    return (
        <Fragment>
            <Button {...other} size="small" variant="contained" style={{ width: '100%' }}>
                <small> {name || ''}</small>
            </Button>
        </Fragment>
    );
};

const ButtonFormaPago = (props) => {
    const { name, icon, ...other } = props;

    return (
        <Fragment>
            <Button {...other} size="small" variant="contained" style={{ width: '100%' }}>
                <small>{name || ''}</small>
            </Button>
        </Fragment>
    );
};

const ButtonReporte = (props) => {
    const { name, icon, startIcon, color, disabled, ...other } = props;

    return (
        <Tooltip title={"Imprimir"} placement="top">
            <Button
                onClick={props.onClick}
                variant="contained"
                style={{ width: '100%' }}
                size="small"
                color={color || 'info'}
                disabled={disabled || false}
            // startIcon={startIcon || <LocalPrintshopIcon fontSize="inherit" />}
            >
                {icon || <LocalPrintshopIcon fontSize="inherit" />} <small>{name || ''}</small>
            </Button>
        </Tooltip>
    );
};

const ButtonCloseModal = (props) => {
    const { name, icon, ...other } = props;

    return (
        <>
            <IconButton
                aria-label="close"
                onClick={props.cerrarModal}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500]
                }}
                size="small"
            >
                {icon || <CloseIcon fontSize="inherit" />}
            </IconButton>
        </>
    );
};

const FabAutorizando = (props) => {
    const { name, icon, estado, ...other } = props;
    const theme = useTheme();
    return (
        <>
            <Tooltip title={name || ''}>
                <Fab
                    component="div"
                    size="medium"
                    variant="circular"
                    color="primary"
                    sx={{
                        borderRadius: 0,
                        borderTopLeftRadius: '50%',
                        borderBottomLeftRadius: '50%',
                        borderTopRightRadius: '50%',
                        borderBottomRightRadius: '4px',
                        top: '25%',
                        position: 'fixed',
                        right: '50%',
                        zIndex: theme.zIndex.speedDial
                    }}
                    style={{ display: estado ? 'block' : 'none' }}
                >
                    <AnimateButton type="rotate">
                        <IconButton color="inherit" size="large" disableRipple>
                            {icon || <RotateRightIcon />}
                        </IconButton>
                    </AnimateButton>
                </Fab>
            </Tooltip>
        </>
    );
};

const ButtonRetener = (props) => {
    const { name, icon, event, evtdisabled, ...other } = props;

    return (
        <Fragment>
            {/* <Tooltip title="Retener" placement="top" disabled={false || evtdisabled}> */}
            <Button
                disabled={false || evtdisabled}
                onClick={event}
                startIcon={icon || <MonetizationOnIcon fontSize="inherit" />}
                variant="contained"
                color="warning"
                size="small"
                sx={{ width: '100%' }}
            >
                <small> {name || ''}</small>
            </Button>

            {/* </Tooltip> */}
        </Fragment>
    );
};
const ButtonAnular = (props) => {
    const { name, icon, event, evtdisabled, ...other } = props;

    return (
        <Fragment>
            {/* //<Tooltip title="Anular" placement="top" disabled={false || evtdisabled}> */}
            <Button
                onClick={event}
                disabled={false || evtdisabled}
                startIcon={icon || <HighlightOffIcon fontSize="inherit" />}
                variant="contained"
                color="error"
                size="small"
                sx={{ width: '100%' }}
            >
                <small> {name || ''}</small>
            </Button>
            {/* //</Tooltip> */}
        </Fragment>
    );
};

const ButtonModal = (props) => {
    const { name1, name2, icon, event, evtdisabled, ...other } = props;

    return (
        <Fragment>
            <Button type="submit" disabled={evtdisabled || false} color="success" size="small" variant="contained" startIcon={<SaveIcon fontSize='inherit' /> || icon}>
                {name2 || 'Guardar'}
            </Button>
            <Button onClick={event} color="error" size="small" variant="contained" startIcon={<CloseIcon fontSize='inherit' /> || icon}>
                {name1 || 'Cancelar'}
            </Button>
        </Fragment>
    );
};

const TextFieldSearchTable = (props) => {
    const { name, placeholder, icon, event, evtdisabled, ...other } = props;

    return (
        <Fragment>
            <TextField
                onChange={event}
                style={{ width: '100%', background: 'white' }}
                size="small"
                id="outlined-basic"
                label={name || ''}
                placeholder={placeholder || ''}
                variant="outlined"
                InputLabelProps={{ style: { fontSize: 12 }, shrink: true }}
                InputProps={{
                    endAdornment: <InputAdornment position="end">{icon || ''}</InputAdornment>,
                    style: { height: 25, fontSize: 12 }
                }}
            />
        </Fragment>
    );
};
const CustomLabel = (props) => {
    const { text, variant, fontSize, color, align } = props;

    return (
        <Typography sx={{ fontSize: fontSize }} align={align || 'left'} noWrap={true} variant={variant} fontSize="small">
            {text}
        </Typography>
    );
};

const ButtonAny = (props) => {
    const { name, icon, color, type, variant, startIcon, ...other } = props;

    return (
        <Button
            {...other}
            type={type || 'button'}
            startIcon={startIcon || ''}
            size="small"
            variant={variant || 'contained'}
            color={color || 'success'}
            style={{ width: '100%' }}
        >
            {icon || ''}
            <small>{name || ''}</small>
        </Button>
    );
};

export {
    ButtonModal,
    ButtonAdd,
    ButtonEdit,
    ButtonDelete,
    ButtonBaja,
    ButtonRoles,
    ButtonAddDetails,
    ButtonCancel,
    ButtonSave,
    ButtonInfoPago,
    ButtonCloseModal,
    ButtonReporte,
    ButtonFormaPago,
    ButtonRetener,
    ButtonAnular,
    FabAutorizando,
    TextFieldSearchTable,
    CustomLabel,
    ButtonAny
};
