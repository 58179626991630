import PropTypes from 'prop-types';
import React, { Fragment, useEffect, useState } from 'react';
// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Box,
    Divider,
    Drawer,
    InputAdornment,
    TextField,
    Typography,
    useMediaQuery
} from '@mui/material';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';
import { BrowserView, MobileView } from 'react-device-detect';
import MenuList from './MenuList';
import LogoSection from '../LogoSection';
import MenuCard from './MenuCard';
import { drawerWidth } from 'store/constant';
import SearchIcon from '@mui/icons-material/Search';
import MenuService from 'services/Menu/MenuService';
import NavItem from './MenuList/NavItem';

// ==============================|| SIDEBAR DRAWER ||============================== //

const Sidebar = ({ drawerOpen, drawerToggle, window }) => {
    console.log('invocando componente Sidebar');
    const theme = useTheme();
    const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));
    const ambiente = JSON.parse(localStorage.getItem('ambiente'));
    const nombreSucursal = JSON.parse(localStorage.getItem('nombreSucursal'));
    const rolTipo = JSON.parse(localStorage.getItem('nombreRolTipo'));
    const usuario = JSON.parse(localStorage.getItem('user'));
    const [menuList, setMenuList] = useState([]);
    const [search, setSearch] = useState('');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        MenuService.Get()
            .then(async (result) => {
                if (result.code === '1') {
                    setMenuList(result.payload ? JSON.parse(result.payload) : []);
                    setLoading(false);
                } else {
                    setMenuList([]);

                }
            })
            .catch((e) => {
                console.log(e.message);
            })
        // .finally(() => {
        //     setLoading(false);
        // });
    }, []);

    const requestSearch = (e) => {
        const value = e.target.value;
        setSearch(value);
    };

    const results = !search ? menuList : menuList.filter((dato) => dato?.title.toLowerCase().includes(search.toLocaleLowerCase()));

    const drawer = (
        <>
            <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                <Box sx={{ display: 'flex', p: 2, mx: 'auto' }}>
                    <LogoSection />
                </Box>
            </Box>
            <BrowserView>
                <PerfectScrollbar
                    component="div"
                    style={{
                        height: !matchUpMd ? 'calc(100vh - 56px)' : 'calc(100vh - 88px)',
                        paddingLeft: '16px',
                        paddingRight: '16px'
                    }}
                >
                    <Box>
                        <Divider></Divider>
                        <Typography variant="h5" gutterBottom>
                            Bienvenido {'(a)'}: <strong>{usuario}</strong> , accediendo como <strong>{rolTipo}</strong>
                        </Typography>
                        <Typography variant="subtitle2" gutterBottom>
                            <strong>Sucursal: {nombreSucursal}</strong>
                        </Typography>
                        <Typography variant="h6" gutterBottom>
                            Conectado en Ambiente de: <strong>{ambiente}</strong>
                        </Typography>
                        <Divider></Divider>
                    </Box>
                    <Box>
                        <TextField
                            onChange={(e) => requestSearch(e)}
                            style={{ width: '100%' }}
                            size="small"
                            id="search-basic"
                            label=""
                            variant="outlined"
                            autoComplete="off"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <SearchIcon fontSize="small" />
                                    </InputAdornment>
                                ),
                                style: { height: 25, fontSize: 12 }
                            }}
                            InputLabelProps={{ shrink: true }}
                        />
                    </Box>
                    <MenuCard />
                    {loading ? (
                        <p>Loading...</p>
                    ) : (
                        <MenuList menuList={results} />

                    )}
                    {/* <MenuList menuList={menuList} /> */}
                </PerfectScrollbar>
            </BrowserView>
            {/* <MobileView>
                <Box sx={{ px: 2 }}>
                    <MenuList />
                    <MenuCard />
                </Box>
            </MobileView> */}
        </>
    );

    const container = window !== undefined ? () => window.document.body : undefined;

    return (
        <Box component="nav" sx={{ flexShrink: { md: 0 }, width: matchUpMd ? drawerWidth : 'auto' }} aria-label="mailbox folders">
            <Drawer
                container={container}
                variant={matchUpMd ? 'persistent' : 'temporary'}
                anchor="left"
                open={drawerOpen}
                onClose={drawerToggle}
                sx={{
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        background: theme.palette.background.default,
                        color: theme.palette.text.primary,
                        borderRight: 'none',
                        [theme.breakpoints.up('md')]: {
                            top: '88px'
                        }
                    }
                }}
                ModalProps={{ keepMounted: true }}
                color="inherit"
            >
                {drawer}
            </Drawer>
        </Box>
    );
};

Sidebar.propTypes = {
    drawerOpen: PropTypes.bool,
    drawerToggle: PropTypes.func,
    window: PropTypes.object
};

export default Sidebar;
