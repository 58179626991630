import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

const Gasto_AASINet = Loadable(lazy(() => import('component/ContabilizacionAASINet/Gasto_AASINet')));

const Pago_AASINet = Loadable(lazy(() => import('component/ContabilizacionAASINet/Pago_AASINet')));
const TransferenciaBanco = Loadable(lazy(() => import('component/ContabilizacionAASINet/TransferenciaBanco/TransfereciaBanco')));


// sample page routing
const SamplePage = Loadable(lazy(() => import('views/sample-page')));

// ==============================|| MAIN ROUTING ||============================== //

const AASINetRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/AASINet/Pago_AASINet', //TAPCONTEXT
            element: <Pago_AASINet />
        },
        {
            path: '/AASINet/Gasto_AASINet', //TAPCONTEXT
            element: <Gasto_AASINet />
        },
        {
            path: '/ContabilizacionAASINET/TransferenciaBanco', 
            element: <TransferenciaBanco />
        }
    ]
};

export default AASINetRoutes;
