import methods from "../Service";
import useHttpParamsJsonCreate from "hooks/useHttpParamsJsonCreate";
const useParamsJson = (param) => { return useHttpParamsJsonCreate(param); }
const login = {
    async Get() {
        return await methods.Get("Login/Get");
    },
    async Post(data) {
        return await methods.Post("Login/Post", data);
    },
    async GetChange(qs) { return await methods.Get("Login/GetChange" + useParamsJson(qs)) },

    verifyLogin: () => {
        console.log("LoginService.jsx verifyLogin")
        /* objeto */
        if (!localStorage) {
            /* x error */
            return true
        }
        /* cantidad opcional */
        // if (localStorage.length <= 0) {
        //     /* x error */
        //     return true
        // }

        if (!localStorage.getItem("hour")) {
            return true
        }
        
        if (!localStorage.getItem("minute")) {
            return true
        }
        
        if (!localStorage.getItem("second")) {
            return true
        }

        // if (!localStorage.getItem("menus")) { /* null, undefined */
        //     /* x error */
        //     localStorage.clear()
        //     localStorage.setItem("go", true);
        //     return true
        // }

        // if (!localStorage.getItem("menuDetalle")) { /* null, undefined */
        //     /* x error */
        //     localStorage.clear()
        //     localStorage.setItem("go", true);
        //     return true
        // }

        if (!localStorage.getItem("roles")) { /* null, undefined */
            /* x error */
            localStorage.clear()
            localStorage.setItem("go", true);
            return true
        }

        if (!localStorage.getItem("JWT")) { /* null, undefined */
            /* x error */
            localStorage.clear()
            localStorage.setItem("go", true);
            return true
        }

        if (!localStorage.getItem("user")) { /* null, undefined */
            /* x error */
            localStorage.clear()
            localStorage.setItem("go", true);
            return true
        }

        if (!localStorage.getItem("codigoUsuario")) { /* null, undefined */
            /* x error */
            localStorage.clear()
            localStorage.setItem("go", true);
            return true
        }
        /* recuperar data, parseandola */
        // const menu = JSON.parse(localStorage.getItem("menus"))
        // if (menu === null) { /* null, undefined */
        //     /* x error */
        //     localStorage.clear()
        //     localStorage.setItem("go", true);
        //     return true
        // }

        // const menuDetalle = JSON.parse(localStorage.getItem("menuDetalle"))
        // if (menuDetalle === null) { /* null, undefined */
        //     /* x error */
        //     localStorage.clear()
        //     localStorage.setItem("go", true);
        //     return true
        // }

        const roles = JSON.parse(localStorage.getItem("roles"))
        if (roles === null) { /* null, undefined */
            /* x error */
            localStorage.clear()
            localStorage.setItem("go", true);
            return true
        }

        const JWT = JSON.parse(localStorage.getItem("JWT"))
        if (JWT === null) { /* null, undefined */
            /* x error */
            localStorage.clear()
            localStorage.setItem("go", true);
            return true
        }

        const user = JSON.parse(localStorage.getItem("user"))
        if (user === null) { /* null, undefined */
            /* x error */
            localStorage.clear()
            localStorage.setItem("go", true);
            return true
        }

        const codigoUsuario = JSON.parse(localStorage.getItem("codigoUsuario"))
        if (codigoUsuario === null) { /* null, undefined */
            /* x error */
            localStorage.clear()
            localStorage.setItem("go", true);
            return true
        }

        return false;
    }
};
export default login;