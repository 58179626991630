// assets

import PaidIcon from '@mui/icons-material/Paid';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
// constant


// ==============================|| UTILITIES MENU ITEMS ||============================== //

const contabilizacion = {
    id: 'contabilizacion',  
    type: 'group',
    children: [    
      
        {
            id: 'contabilizac',
            title: 'Contabilización',
            type: 'collapse',
            icon: PointOfSaleIcon,

            children: [
                {
                    id: 'borrador',
                    title: 'Registros en borrador',
                    type: 'item',
                    url: '/Contabilizacion/ContabilizacionTapContext',
                    breadcrumbs: false
                 },
                {
                    id: 'revision',
                    title: 'Por Contabilizar / Revisión',
                    type: 'item',
                    url: '/Contabilizacion/ContabilizacionRevisionTapContext',
                    breadcrumbs: false
                }
                ,
                {
                    id: 'contabilizado',
                    title: 'Contabilizados',
                    type: 'item',
                    url: '/Contabilizacion/ListaAsientoContable',
                    breadcrumbs: false
                }
            ]
        }
    ]
};

export default contabilizacion;
