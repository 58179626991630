import { Button, Snackbar } from '@mui/material';
import { toast } from 'material-react-toastify';
import 'material-react-toastify/dist/ReactToastify.css';
import { forwardRef, Fragment } from 'react';
import MuiAlert from '@mui/material/Alert';
import Swal from 'sweetalert2';
const CustomToastComponent = ({ message }) => {
    return <div dangerouslySetInnerHTML={{ __html: message }} />;
};
export const saveNotification = (message) => {
    toast.success(<CustomToastComponent message={message} />, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
    });
};
export const errorNotification = (message) => {
    toast.error('' + message + '', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
    });
};

export const warningNotification = (message) => {
    toast.warning(<CustomToastComponent message={message} />, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
    });
};

export const warningNotification1 = (message, message2 = undefined, autoClose = undefined, autoCloseUpdate = undefined) => {


    const id = toast.warning(<CustomToastComponent message={message} />, {
        position: 'top-right',
        autoClose: autoClose || 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
    setTimeout(() => {

        toast.update(id, {
            type: toast.TYPE.INFO,
            render: message2 || "NO message",
            autoClose: autoCloseUpdate
        })
    }, 2000);

};

export const errorSwalNotification = (message, title = '') => {
    Swal.fire({
        target: document.getElementById('dialog'),
        title: '<small>' + title + '</small>',
        html: '<small>  <p align="center">' + message + '</p> </small>',
        icon: 'error'
    });
};

const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const MessageError = (props) => {
    return (
        <Fragment>
            <Snackbar
                open={props.open}
                autoHideDuration={5000}
                onClose={props.onClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <Alert onClose={props.onClose} severity="error" sx={{ width: '50%' }}>
                    {props.message}
                </Alert>
            </Snackbar>
        </Fragment>
    );
};

export default MessageError;

// export const saveNotification = (message, messageTitle) => {

//   toast.success(<div
//     style={{
//       height: "100%",
//       borderLeft: "5px solid green",
//       display: "flex",
//       alignItems: "center",
//       paddingLeft: 5
//     }}
//   >
//     <CheckCircleOutlineIcon color={"green"} height="25px" width="25px" />
//     <span style={{ fontWeight: "bold", color: "#000" }}>{message}</span>
//     {"  "}<br></br>
//     <span style={{ marginLeft: 5 }}>{messageTitle}</span>
//   </div>,

//   {
//       position: "top-right",
//       autoClose: 3000,
//       hideProgressBar: false,
//       closeOnClick: true,
//       pauseOnHover: true,
//       draggable: true,
//       progress: undefined,
//       newestOnTop:false,
//       rtl:false,

//     });
// };
