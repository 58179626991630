import { InputBase } from "@mui/material";
import { styled } from "@mui/system";

export const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
      marginTop: theme.spacing(0.5),
    },
    '& .MuiInputBase-input': {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: 'theme.palette.background.paper',
      border: '1px  solid #ced4da',
      fontSize: 13,
      padding: '10px 26px 10px 12px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      // Use the system font instead of the default Roboto font.         
      fontWeight: 'bold',
       fontFamily: 'Roboto',
       // [
      //   '-apple-system',
      //   'BlinkMacSystemFont',
      //   '"Segoe UI"',
       
      //   '"Helvetica Neue"',
      //   'Arial',
      //   'sans-serif',
      //   '"Apple Color Emoji"',
      //   '"Segoe UI Emoji"',
      //   '"Segoe UI Symbol"',
      // ].join(','),
      '&:focus': {
        borderRadius: 4,
        borderColor: '#80bdff',
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        
      },
    },
  }));