import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import ProtectedRoute from './ProtectedRoute';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));

// utilities routing
const Ambiente = Loadable(lazy(() => import('component/Configuracion/Ambiente/Ambiente')));

const AperturaPeriodo = Loadable(lazy(() => import('component/Configuracion/Periodo/AperturaPeriodo')));
const Usuarios = Loadable(lazy(() => import('component/Configuracion/Usuario/ListaUsuario')));
const MiPerfil = Loadable(lazy(() => import('component/Configuracion/Usuario/MiPerfil')));

const PuntoEmision = Loadable(lazy(() => import('component/Configuracion/PuntoEmision/PuntoEmision')));
const RetencionSecuencialElect = Loadable(lazy(() => import('component/Configuracion/SecuencialElectronica/SecuencialElectronica')));

const ConfigCuenta = Loadable(lazy(() => import('component/Configuracion/ConfigCuenta/ConfigCuenta')));

const RegimenFiscalExterior = Loadable(lazy(() => import('component/Configuracion/ConfigRegimenFiscalExterior/RegimenFiscalExterior')));
const Pais = Loadable(lazy(() => import('component/Configuracion/ConfigPais/Pais')));
const ProveedorTipo = Loadable(lazy(() => import('component/Configuracion/ConfigProveedorTipo/ProveedorTipo')));
const IdentificacionTipo = Loadable(lazy(() => import('component/Configuracion/ConfigIdentificacionTipo/IdentificacionTipo')));
const ImpuestoTarifa = Loadable(lazy(() => import('component/Configuracion/ConfigImpuestoTarifa/ImpuestoTarifa')));
const AsignacionRol = Loadable(lazy(() => import('component/Configuracion/Usuario/AsignacionRol')));

//Proveedor
const Proveedor = Loadable(lazy(() => import('component/Proveedor/ListarProveedor')));
const ProveedorEstablecimiento = Loadable(lazy(() => import('component/Proveedor/ListarProveedorEstablecimiento')));

const PersonaLiquidacion = Loadable(lazy(() => import('component/Proveedor/ListarPersonaLiquidacion')));

//Retenciones
const AnulacionRetencion = Loadable(lazy(() => import('component/Retencion/AnulacionRetencion')));
// const Retenciones = Loadable(lazy(() => import('component/Retencion/Retenciones')));

//Proyecto
const Proyecto = Loadable(lazy(() => import('component/Proyecto/Proyecto')));
const DepartamentoActividad = Loadable(lazy(() => import('component/Proyecto/DepartamentoActividad')));

//contabilidad
const ContabilizacionTapContext = Loadable(lazy(() => import('component/ContabilizacionAASINet/AsientoGasto/ContabilizacionBorradorTapContext')));
const ListarAsiento = Loadable(lazy(() => import('component/ContabilizacionAASINet/AsientoGasto/ContabilizacionRevisionTapContext')));

//ATS
const ATS = Loadable(lazy(() => import('component/ATS/GenerarATS')));
//Comprobantes
const FacturasEmitidas = Loadable(lazy(() => import('component/Comprobante/FacturasEmitidas')));
const ComprobanteGeneral = Loadable(lazy(() => import('component/Comprobante/ComprobanteGeneral')));
//XML COMPRA
const XMLCompra = Loadable(lazy(() => import('component/XmlCompra/GenerarXmlCompra')));

const PartidaPresupuestaria = Loadable(lazy(() => import('component/Contabilizacion/ListaPartidaPresupuestaria')));
const Documentos = Loadable(lazy(() => import('component/Documentos/Documentos')));

const Factura = Loadable(lazy(() => import('component/Factura/Factura')));
const NotaVenta = Loadable(lazy(() => import('component/NotaVenta/NotaVenta')));
const LiquidacionCompra = Loadable(lazy(() => import('component/LiquidacionCompra/LiquidacionCompra')));
const NotaCredito = Loadable(lazy(() => import('component/NotaCredito/NotaCredito')));
const ListarFactura = Loadable(lazy(() => import('component/NotaCredito/ListarFactura')));
const ComprobanteExterior = Loadable(lazy(() => import('component/ComprobanteExterior/ComprobanteExterior')));

const Ticket = Loadable(lazy(() => import('component/Ticket/Ticket')));
const Reembolso = Loadable(lazy(() => import('component/Reembolso/Reembolso')));
const Aviacion = Loadable(lazy(() => import('component/Aviacion/Aviacion')));

const Color = Loadable(lazy(() => import('views/utilities/Color')));
const Shadow = Loadable(lazy(() => import('views/utilities/Shadow')));
const MaterialIcons = Loadable(lazy(() => import('views/utilities/MaterialIcons')));
const TablerIcons = Loadable(lazy(() => import('views/utilities/TablerIcons')));

const Mobile = Loadable(lazy(() => import('component/Mobile/ComprobanteMobile')));

const ListaRetencion = Loadable(lazy(() => import('component/ComprobanteSolicitudAnulacion/ListaRetencion')));
const ListaSolicitudAnulacion = Loadable(lazy(() => import('component/ComprobanteSolicitudAnulacion/ListaSolicitudAnulacion')));
const ListaAutorizadas = Loadable(lazy(() => import('component/ComprobanteSolicitudAnulacion/ListaAutorizadaAnulacion')));
const ListaAnuladas = Loadable(lazy(() => import('component/ComprobanteSolicitudAnulacion/ListaAnuladas')));
const ListaNoAutorizada = Loadable(lazy(() => import('component/Retencion/ListaNoAutorizada')));

const ListaLiquidacion = Loadable(lazy(() => import('component/ComprobanteSolicitudAnulacion/LiquidacionCompra/ListaLiquidacion')));
const ListaSolicitudAnulacionLc = Loadable(
    lazy(() => import('component/ComprobanteSolicitudAnulacion/LiquidacionCompra/ListaSolicitudAnulacion'))
);
const ListaAutorizadasLc = Loadable(
    lazy(() => import('component/ComprobanteSolicitudAnulacion/LiquidacionCompra/ListaAutorizadaAnulacion'))
);
const ListaAnuladasLc = Loadable(lazy(() => import('component/ComprobanteSolicitudAnulacion/LiquidacionCompra/ListaAnuladas')));
const ListaNoAutorizadaLc = Loadable(lazy(() => import('component/LiquidacionCompra/ListaNoAutorizada')));


const ListadoParametro = Loadable(lazy(() => import('component/Reportes/Retencion/listadoXParametro')));

const ConsolidadoCompra = Loadable(lazy(() => import('component/Reportes/ConsolidadoCompra')));
const ConsolidadoCompraDesglosado = Loadable(lazy(() => import('component/Reportes/ConsolidadoCompraDesglosado')));

const ListaGeneralCompra = Loadable(lazy(() => import('component/Reportes/ListadoGeneralCompra')));
const ListaGeneralComprobante = Loadable(lazy(() => import('component/Reportes/ListadoGeneralComprobante')));
const ListadoProveedorEstablecimiento = Loadable(lazy(() => import('component/Reportes/ListadoProveedorEstablecimiento')));
const ListadoPersonaLiquidacion = Loadable(lazy(() => import('component/Reportes/ListadoPersonaLiquidacion')));
const ListadoGeneralXSustento = Loadable(lazy(()=> import('component/Reportes/ListadoGeneralXSustento')));

const InfoPagoComprobanteModal = Loadable(lazy(() => import('component/InfoPagoComprobanteModal')));

const Retencion = Loadable(lazy(() => import('component/ModalRetencion')));
const EjecucionPresupuestaria = Loadable(lazy(() => import('component/Contabilizacion/PartidaPresupuestaria')));

// sample page routing
const SamplePage = Loadable(lazy(() => import('views/sample-page')));

//Contabiliadad / Journal
const JournalItemTemp = Loadable(lazy(() => import('component/ContabilizacionAASINet/AsientoGasto/ModalAsientoContableTemp')));
const JournalItemPago = Loadable(lazy(() => import('component/ContabilizacionAASINet/AsientoPago/ModalAsientoContableTempPago')));
const ComprobanteNoAutorizado = Loadable(lazy(() => import('component/ComprobanteNoAutorizado/ComprobanteNoAutorizado')));
const SolicitudAnulacion = Loadable(lazy(() => import('component/SolicitudAnulacion/SolcitudAnulacion')));
const HistorialSolicitudes = Loadable(lazy(() => import('component/SolicitudAnulacion/HistorialSolicitudes')));
const SolicitudAnulacionMaster = Loadable(lazy(() => import('component/SolicitudAnulacion/SolicitudAnulacionMaster')));




// ==============================|| MAIN ROUTING ||============================== //

console.log("declaracion de rutas");

const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/',
            element: <ProtectedRoute element={<DashboardDefault />} />
        },
        {
            path: '/dashboard/default',
            element: <ProtectedRoute element={<DashboardDefault />} />
        },
        {
            path: '/Configuracion/AperturaPeriodo',
            element: <AperturaPeriodo />
        },
        {
            path: '/Configuracion/Ambiente',
            element: <Ambiente />
        },
        {
            path: '/Configuracion/Usuario',
            element: <Usuarios />
        },
        {
            path: '/Configuracion/Usuario/MiPerfil',
            element: <MiPerfil />
        },
        {
            path: '/Usuario/AsignacionRol',
            element: <AsignacionRol />
        },
        {
            path: '/Configuracion/PuntoEmision',
            element: <PuntoEmision />
        },
        {
            path: '/Configuracion/SecuencialElectronica',
            element: <RetencionSecuencialElect />
        },
        {
            path: '/Configuracion/ConfigCuenta',
            element: <ConfigCuenta />
        },
        {
            path: '/Configuracion/RegimenFiscalExterior',
            element: <RegimenFiscalExterior />
        },
        {
            path: '/Configuracion/Pais',
            element: <Pais />
        },
        {
            path: '/Configuracion/ProveedorTipo',
            element: <ProveedorTipo />
        },
        {
            path: '/Configuracion/IdentificacionTipo',
            element: <IdentificacionTipo />
        },
        {
            path: '/Configuracion/ImpuestoTarifa',
            element: <ImpuestoTarifa />
        },
        {
            path: '/Retencion/AnulacionRetencion',
            element: <AnulacionRetencion />
        },

        {
            path: '/Proyecto/Proyecto',
            element: <Proyecto />
        },
        {
            path: '/Proyecto/DepartamentoActividad',
            element: <DepartamentoActividad />
        },
        {
            path: '/Contabilizacion/ContabilizacionTapContext',
            element: <ContabilizacionTapContext />
        },
        {
            path: '/Contabilizacion/ContabilizacionRevisionTapContext',
            element: <ListarAsiento />
        },
        {
            path: '/Contabilizacion/PartidaPresupuestaria',
            element: <EjecucionPresupuestaria />
        },
        {
            path: '/ATS/GenerarATS',
            element: <ATS />
        },
        {
            path: '/Comprobante/ComprobanteGeneral',
            element: <ComprobanteGeneral />
        },
        {
            path: '/Comprobante/FacturasEmitidas',
            element: <FacturasEmitidas />
        },
        {
            path: '/XmlCompra/GenerarXmlCompra',
            element: <XMLCompra />
        },
        {
            path: '/Contabilizacion/ListaPartidaPresupuestaria',
            element: <PartidaPresupuestaria />
        },
        {
            path: '/documentos',
            element: <Documentos />
        },
        {
            path: '/Factura/Factura',
            element: <Factura />
        },
        {
            path: '/NotaVenta/NotaVenta',
            element: <NotaVenta />
        },
        {
            path: '/LiquidacionCompra/LiquidacionCompra',
            element: <LiquidacionCompra />
        },
        {
            path: '/NotaCredito/NotaCredito',
            element: <NotaCredito />
        },
        {
            path: '/NotaCredito/ListarFactura',
            element: <ListarFactura />
        },
        {
            path: '/Ticket/Ticket',
            element: <Ticket />
        },
        {
            path: '/Reembolso/Reembolso',
            element: <Reembolso />
        },
        {
            path: '/Aviacion/Aviacion',
            element: <Aviacion />
        },
        {
            path: '/ComprobanteExterior/ComprobanteExterior',
            element: <ComprobanteExterior />
        },
        {
            path: '/Proveedor/ListarProveedor',
            element: <Proveedor />
        },
        {
            path: '/Proveedor/ListarProveedorEstablecimiento',
            element: <ProveedorEstablecimiento />
        },
        {
            path: '/Proveedor/ListarPersonaLiquidacion',
            element: <PersonaLiquidacion />
        },
        {
            path: '/Mobile/ComprobanteMobile',
            element: <Mobile />
        },
        //INICIO- SOLICITUD DE ANULACION RETENCION
        {
            path: '/ComprobanteSolicitudAnulacion/ListaRetencion',
            element: <ListaRetencion />
        },
        {
            path: '/ComprobanteSolicitudAnulacion/ListaSolicitudAnulacion',
            element: <ListaSolicitudAnulacion />
        },
        {
            path: '/ComprobanteSolicitudAnulacion/ListaAutorizadaAnulacion',
            element: <ListaAutorizadas />
        },
        {
            path: '/ComprobanteSolicitudAnulacion/ListaAnuladas',
            element: <ListaAnuladas />
        },
        //FIN - SOLICITUD DE ANULACION RETENCION
        {
            path: '/Retencion/ListaNoAutorizada',
            element: <ListaNoAutorizada />
        },
        //INICIO - SOLICITU DE ANULACION LIQUIDACION
        {
            path: '/LiquidacionCompra/ListaLiquidacion',
            element: <ListaLiquidacion />
        },
        {
            path: '/LiquidacionCompra/ListaSolicitudAnulacion',
            element: <ListaSolicitudAnulacionLc />
        },
        {
            path: '/LiquidacionCompra/ListaAutorizadaAnulacion',
            element: <ListaAutorizadasLc />
        },
        {
            path: '/LiquidacionCompra/ListaAnuladas',
            element: <ListaAnuladasLc />
        },
        //FIN - SOLICITU DE ANULACION LIQUIDACION
        {
            path: '/LiquidacionCompra/ListaNoAutorizada',
            element: <ListaNoAutorizadaLc />
        },       
        {
            path: '/Retencion/listadoXParametro',
            element: <ListadoParametro />
        },
        {
            path: '/Reportes/ListadoGeneralComprobante',
            element: <ListaGeneralComprobante />
        },
        {
            path: '/Reportes/ListadoGeneralCompra',
            element: <ListaGeneralCompra />
        },
        {
            path: '/Reportes/ListadoGeneralXSustento',
            element: <ListadoGeneralXSustento />
        },
        {
            path: '/Reportes/ConsolidadoCompra',
            element: <ConsolidadoCompra />
        },
        {
            path: '/Reportes/ConsolidadoCompraDesglosado',
            element: <ConsolidadoCompraDesglosado />
        },
        {
            path: '/Reportes/ListadoProveedoresEstablecimiento',
            element: <ListadoProveedorEstablecimiento />
        },
        {
            path: '/Reportes/ListadoPersonaLiquidacion',
            element: <ListadoPersonaLiquidacion />
        },
        {
            path: '/utils/util-color',
            element: <Color />
        },
        {
            path: '/utils/util-shadow',
            element: <Shadow />
        },
        {
            path: '/icons/tabler-icons',
            element: <TablerIcons />
        },
        {
            path: '/icons/material-icons',
            element: <MaterialIcons />
        },
        {
            path: '/sample-page',
            element: <SamplePage />
        },
        {
            path: '/InfoPagoComprobanteModal',
            element: <InfoPagoComprobanteModal />
        },
        {
            path: '/Retencion',
            element: <Retencion />
        },
        {
            path: '/JournalItemTemp',
            element: <JournalItemTemp />
        },
        {
            path: '/JournalItemPago',
            element: <JournalItemPago />
        },
        {
            path: '/ComprobanteNoAutorizado/ComprobanteNoAutorizado',
            element: <ComprobanteNoAutorizado />
        },
        {
            path: '/SolicitudAnulacion/SolicitudAnulacion',
            element: <SolicitudAnulacion />
        },
        {
            path: '/SolicitudAnulacion/HistorialSolicitudes',
            element: <HistorialSolicitudes />
        },
        {
            path: '/SolicitudAnulacion/SolicitudAnulacionMaster',
            element: <SolicitudAnulacionMaster />
        },
      
    ]
};

export default MainRoutes;
