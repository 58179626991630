// assets
import { IconDashboard } from '@tabler/icons';
import DescriptionIcon from '@mui/icons-material/Description';
// constant
const icons = { IconDashboard };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const documento = {
    id: 'comprobante2',
    title: '',
    type: 'group',
    children: [
        {
            id: 'documents',
            title: 'Documentos',
            type: 'item',
            url: '/documentos',
            icon: DescriptionIcon,
            breadcrumbs: false
        }
    ]
};

export default documento;
