// assets

import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import LinkIcon from '@mui/icons-material/Link';
// constant

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const Contabilizacion_AASSINet = {
    id: 'contabilizacion_AASSINet',

    type: 'group',
    children: [
        {
            id: 'gastos_pagos',
            title: 'Enlace AASINet',
            type: 'collapse',
            icon: LinkIcon,

            children: [
                {
                    id: 'gasto',
                    title: 'Gastos',
                    type: 'item',
                    url: '/AASINet/Gasto_AASINet',
                    breadcrumbs: false
                },
                {
                    id: 'pago',
                    title: 'Pagos',
                    type: 'item',
                    url: '/AASINet/Pago_AASINet',
                    breadcrumbs: false
                },
                
                // {
                //     id: 'transferenciaBanco',
                //     title: 'Transferencia Banco',
                //     type: 'item',
                //     url: '/ContabilizacionAASINET/TransferenciaBanco',
                //     breadcrumbs: false
                // },
                // {
                //     id: 'contabilizado',
                //     title: 'Contabilizado',
                //     type: 'item',
                //     url: '/AASINet/Contabilizado',
                //     breadcrumbs: false
                // }
            ]
        }
    ]
};

export default Contabilizacion_AASSINet;
