import { useRoutes } from 'react-router-dom';

// routes
import MainRoutes from './MainRoutes';
import AuthenticationRoutes from './AuthenticationRoutes';
import AASINetRoutes from './AASINet';
import SIARoutes from './SIA';
import V2Contabilizacion from './V2Contabilizacion';
//import config from 'config';


// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {

    return useRoutes([AuthenticationRoutes, MainRoutes, AASINetRoutes, SIARoutes, V2Contabilizacion]);
}
