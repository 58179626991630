// assets

import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import LinkIcon from '@mui/icons-material/Link';
// constant

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const Enlace_SIA = {
    id: 'enlace_SIA',

    type: 'group',
    children: [
        {
            id: 'gastos_pagos_SIA',
            title: 'Enlace SIA',
            type: 'collapse',
            icon: LinkIcon,

            children: [
                {
                    id: 'gasto_SIA',
                    title: 'Gastos',
                    type: 'item',
                    url: '/SIA/Gasto_SIA',
                    breadcrumbs: false
                },
                {
                    id: 'pago_SIA',
                    title: 'Pagos',
                    type: 'item',
                    url: '/SIA/Pago_SIA',
                    breadcrumbs: false
                },
                // {
                //     id: 'porContabilizar_SIA',
                //     title: 'Por Contabilizar',
                //     type: 'item',
                //     url: '/SIA/PorContabilizar_SIA',
                //     breadcrumbs: false
                // }
            ]
        }
    ]
};

export default Enlace_SIA;
