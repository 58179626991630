// assets

import CreditScoreIcon from '@mui/icons-material/CreditScore';
// constant
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import SourceIcon from '@mui/icons-material/Source';


// ==============================|| UTILITIES MENU ITEMS ||============================== //

const comprobante = {
    id: 'comprobante1',
    title: '',
    type: 'group',
    children: [
        {
            id: 'compr',
            title: 'Comprobantes',
            type: 'collapse',
            icon: SourceIcon,

            children: [
                {
                    id: 'comprobanteGeneral',
                    title: 'Comprobante General',
                    type: 'item',
                    url: '/Comprobante/ComprobanteGeneral',
                    breadcrumbs: false
                }
                // {
                //     id: 'factEmitidas',
                //     title: 'Facturas Emitidas',
                //     type: 'item',
                //     url: '/Comprobante/FacturasEmitidas',
                //     breadcrumbs: false
                // },
                // {
                //     id: 'factSinRenten',
                //     title: 'Factras sin Retenciones',
                //     type: 'item',
                //     url: '/Comprobante/FacturasSinReten',
                //     breadcrumbs: false
                // },
                // {
                //     id: 'liquiSinReten',
                //     title: 'Liquidaciones sin Reten',
                //     type: 'item',
                //     url: '/Comprobante/LiquidacionSinReten',
                //     breadcrumbs: false
                // },
            ]
        },
        {
            id: 'generarATS',
            title: 'Generar ATS',
            type: 'item',
            url: '/ATS/GenerarATS',
            icon: FileDownloadIcon,
            breadcrumbs: false
        },
        {
            id: 'generarXmlCompras',
            title: 'Generar xml compras',
            type: 'item',
            url: '/XmlCompra/GenerarXmlCompra',
            icon: FileDownloadIcon,
            breadcrumbs: false
        }
    ]
};

export default comprobante;
