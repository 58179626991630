import React, {  useEffect, useState } from 'react';
// material-ui

// assets
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

import {
    FormControlLabel,
    InputAdornment,
    Radio,
    RadioGroup,
    TextField,
    Typography
} from '@mui/material';
import login from 'services/Login/LoginService';
import { warningNotification } from 'utils/toastify';
import { ToastContainer } from 'material-react-toastify';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import SearchIcon from '@mui/icons-material/Search';
import { useForm } from 'react-hook-form';
// ==============================|| SIDEBAR MENU Card ||============================== //

const MenuCard = () => {
    const {
        register,        
    } = useForm();
    const [listaRol, setListaRol] = useState([]);    
    const [idRol, setIdRol] = useState();

    const [open, setOpen] = useState(false);

    const [progress, setProgress] = useState(10);
    const [route, setRoute] = useState();
    const [valid, setValid] = useState();
    const [search, setSearch] = useState('');
    useEffect(() => {
        setListaRol(JSON.parse(localStorage.getItem('roles')));
        setIdRol(localStorage.getItem('idRol'));
    }, []);

    const handleClick = () => {
        setOpen(!open);
    };

    const handleToggle = (e) => {
        const value = e.target.value;
        
        localStorage.setItem('go', true);
        localStorage.removeItem("PeriodoSeleccionado");
      
        login
            .GetChange({ id: value })
            .then(async (result) => {
                if (result.code === '1') {
                    // setLoading((prevLoading) => !prevLoading);

                    const resp = result.payload;

                    //console.log("token login: "+resp.token);
                    //localStorage.setItem("menus", JSON.stringify(resp.menus));
                    //localStorage.setItem("menuDetalle", JSON.stringify(resp.menuDetalle));
                    localStorage.setItem('roles', JSON.stringify(resp.roles));
                    localStorage.setItem('go', false);
                    localStorage.setItem('JWT', JSON.stringify('Bearer ' + resp.token));
                    // console.log("token login despues de local : "+resp.token);
                    localStorage.setItem('codigoUsuario', JSON.stringify(resp.codigoUsuario));
                    localStorage.setItem('user', JSON.stringify(resp.user));
                    localStorage.setItem('idSucursal', JSON.stringify(resp.idSucursal));
                    localStorage.setItem('nombreSucursal', JSON.stringify(resp.nombreSucursal));

                    localStorage.setItem('nombreRolTipo', JSON.stringify(resp.nombreRol));
                    localStorage.setItem('codigoRolTipo', JSON.stringify(resp.codigoRolTipo));

                    localStorage.setItem('ambiente', JSON.stringify(resp.ambiente));
                    localStorage.setItem('idRol', JSON.stringify(resp.idRol));
                    localStorage.setItem('idUsuario', JSON.stringify(resp.idUsuario));
                    localStorage.setItem('hour', JSON.stringify(3));
                    localStorage.setItem('minute', JSON.stringify(54));
                    localStorage.setItem('second', JSON.stringify(59));
                    localStorage.setItem('config', false);
                    // localStorage.setItem('accesoConfiguracionAccount', resp.accesoConfiguracionAccount);
                    // localStorage.setItem('accesoConfiguracionRetencion', resp.accesoConfiguracionRetencion);
                    startSesion(result.redirectTo, login.verifyLogin());
                } else {
                    warningNotification(result.message);
                }
            })
            .catch((e) => {});
    };

    const startSesion = (ruta, valor) => {
        if (!valor) {
            window.location.href = ruta;
            // setLoading((prevLoading) => !prevLoading)
            window.location.reload();
        }
    };

    // const cargando = () => {
    //     const timer = setInterval(() => {
    //         setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 10));

    //     }, 800);

    //     return () => {
    //         clearInterval(timer);
    //     };

    // }

    // useEffect(() => {

    //     if (progress == 100)
    //         startSesion(route, valid);
    // }, [progress])
    const requestSearch = (e) => {
        const value = e.target.value;
        setSearch(value);
    };
    const results = !search ? listaRol : listaRol.filter((dato) => dato.sucursal.toLowerCase().includes(search.toLocaleLowerCase()));

    return (
        <>
            {/* <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}

            >

                <CircularProgress size="300px" color="inherit" variant='determinate' value={progress} />
                <Box
                    sx={{
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        position: 'absolute',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}

                >
                    <Typography variant="h3" gutterBottom >
                        {`${Math.round(progress)}%`}
                    </Typography>

                </Box>
            </Backdrop> */}
            <List
                //   sx={{
                //     width: '100%',
                //     maxWidth: 360,
                //     bgcolor: 'background.paper',
                //     position: 'relative',
                //     overflow: 'auto',
                //     maxHeight: 300,
                //     '& ul': { padding: 0 },
                //   }}
                sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
                component="nav"
                aria-labelledby="nested-list-subheader"
                // component="div"
            >
                <ToastContainer />
                <ListItemButton onClick={handleClick}>
                    <ListItemIcon>
                        <SupervisedUserCircleIcon />
                    </ListItemIcon>
                    <ListItemText primary="Roles" />
                    {open ? <ExpandLess fontSize="small" /> : <ExpandMore fontSize="small" />}
                </ListItemButton>
                <Collapse in={open} timeout="auto" unmountOnExit>
                    
                    {listaRol.length > 1 ? (<>
                        <br></br>
                        <TextField
                            {...register('sucursal')}
                            onChange={(e) => requestSearch(e)}
                            style={{ width: '100%' }}
                            size="small"
                            id="outlined-basic"
                            label="Sucursal"
                            variant="outlined"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <SearchIcon fontSize="small" />
                                    </InputAdornment>
                                ),
                                style: { height: 25, fontSize: 12 }
                            }}
                            InputLabelProps={{ shrink: true }}
                        /></>
                    ) : (
                        ''
                    )}

                    <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue={idRol}
                        name="radio-buttons-group"
                        onChange={(e) => handleToggle(e)}
                    >
                        {results.map((row, index) => (
                            <FormControlLabel
                                key={index + 1}
                                value={row.idRol}
                                control={<Radio size="small" />}
                                label={
                                    <div>
                                        <Typography variant="button" display="block">
                                            <small> {row.sucursal}</small>
                                        </Typography>
                                        <Typography variant="subtitle2">
                                            <small>{row.descripcion}</small>
                                        </Typography>
                                    </div>
                                }
                            />
                        ))}
                    </RadioGroup>
                </Collapse>
            </List>
        </>
    );
};

export default MenuCard;
