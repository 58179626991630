import methods from "../Service";
import useHttpParamsJsonCreate from "hooks/useHttpParamsJsonCreate";
const useParamsJson = (param) => { return useHttpParamsJsonCreate(param); }
const Usuario = {
    async Get() { return await methods.Get("Usuario/Get") },
    async Search(qs) { return await methods.Get("Usuario/Search" + useParamsJson(qs)); },
    async Post(data) { return await methods.Post("Usuario/Post", data) },
    async changePassword(data) { return await methods.Post("Usuario/ChangePassword", data) },

    async Put(qs, data) { return await methods.Put("Usuario/Put" + useParamsJson(qs), data) },
    async Delete(qs) { return await methods.Delete("Usuario/Delete" + useParamsJson(qs)) }
};

export default Usuario;