// assets

import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import AddToHomeScreenIcon from '@mui/icons-material/AddToHomeScreen';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import PaidIcon from '@mui/icons-material/Paid';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
// ==============================|| UTILITIES MENU ITEMS ||============================== //

const utilities = {
    id: 'proyect',
    title: '',
    type: 'group',
    children: [
        {
            id: 'proyectDepartamento',
            title: 'Proyectos',
            type: 'item',
            url: '/Proyecto/Proyecto',
            icon: RequestQuoteIcon,
            breadcrumbs: false
        },
        {
            id: 'ejecucionPresupuestaria',
            title: 'Partida Presupuetaria',
            type: 'item',
            url: '/Contabilizacion/ListaPartidaPresupuestaria',
            icon: AccountTreeIcon,
            breadcrumbs: false
        },

        // {
        //     id: 'contabilizacion',
        //     title: 'Contabilización',
        //     type: 'collapse',
        //     icon: PointOfSaleIcon,

        //     children: [
        //         {
        //             id: 'construirAsiento',
        //             title: 'Construir Asiento Contable',
        //             type: 'item',
        //             url: '/Contabilizacion/ConstruirAsiento',
        //             breadcrumbs: false
        //         },
        //         {
        //             id: 'listaAsientos',
        //             title: 'Lista Asientos Contables',
        //             type: 'item',
        //             url: '/Contabilizacion/ListaAsientoContable',
        //             breadcrumbs: false
        //         }
        //     ]
        // },
        // {
        //     id: 'mobile',
        //     title: 'Mobile',
        //     type: 'item',
        //     url: '/Mobile/ComprobanteMobile',
        //     icon: AddToHomeScreenIcon,
        //     breadcrumbs: false
        // }
        // {
        //     id: 'util-color',
        //     title: 'Color',
        //     type: 'item',
        //     url: '/utils/util-color',
        //     icon: icons.IconPalette,
        //     breadcrumbs: false
        // },
        // {
        //     id: 'util-shadow',
        //     title: 'Shadow',
        //     type: 'item',
        //     url: '/utils/util-shadow',
        //     icon: icons.IconShadow,
        //     breadcrumbs: false
        // },
        // {
        //     id: 'icons',
        //     title: 'Icons',
        //     type: 'collapse',
        //     icon: icons.IconWindmill,
        //     children: [
        //         {
        //             id: 'tabler-icons',
        //             title: 'Tabler Icons',
        //             type: 'item',
        //             url: '/icons/tabler-icons',
        //             breadcrumbs: false
        //         },
        //         {
        //             id: 'material-icons',
        //             title: 'Material Icons',
        //             type: 'item',
        //             url: '/icons/material-icons',
        //             breadcrumbs: false
        //         }
        //     ]
        // }
    ]
};

export default utilities;
