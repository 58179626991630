import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
const Conta_gasto_component = Loadable(lazy(() => import('component/v2/Contabilizacion/conta-gasto-component')));
const Conta_pago_component = Loadable(lazy(() => import('component/v2/Contabilizacion/conta-pago-component')));
const AsientoContableTemp = Loadable(lazy(() => import('component/v2/Contabilizacion/AsientoGasto/ModalAsientoContableTemp')));
const AsientoGastoPage = Loadable(lazy(() => import('component/v2/Contabilizacion/pages/asiento-gasto-page')));

// ==============================|| MAIN ROUTING ||============================== //

const V2Contabilizacion = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/v2/Contabilizacion/ContaGastoComponent',
            element: <Conta_gasto_component />
        },
        {
            path: '/v2/Contabilizacion/ContaPagoComponent',
            element: <Conta_pago_component />
        },
        {
            path: '/v2/Contabilizacion/AsientoGasto/AsientoContableTemp',
            element: <AsientoContableTemp />
        },
        {
            path: '/v2/conta/asiento/gasto',
            element: <AsientoGastoPage />
        }   
    ]
};

export default V2Contabilizacion;
