import methods from "../Service";
import useHttpParamsJsonCreate from "hooks/useHttpParamsJsonCreate";
const useParamsJson = (param) => { return useHttpParamsJsonCreate(param); }
const Periodo = {
    async Get() { return await methods.Get("Periodo/Get"); },
    async Post(data) { return await methods.Post("Periodo/Post", data) },
    async Put(qs) { return await methods.Put("Periodo/Put" + useParamsJson(qs)) },
    async Delete(qs) { return await methods.Delete("Periodo/Delete" + useParamsJson(qs)) },
    async GetBySucursal(qs) { return await methods.Get("Periodo/GetBySucursal" + useParamsJson(qs)) }


};

export default Periodo;