//var _isLook = true;
const Resource = {
    //isLook: _isLook,
    convertObjectToQueryString: function (dataObject) {
        var QueryString = [];
        for (var item in dataObject) {
            if (dataObject.hasOwnProperty(item)) {
                QueryString.push(encodeURIComponent(item) + '=' + encodeURIComponent(dataObject[item]));
            }
        }
        return '?' + QueryString.join('&');
    },

    convertObjectToQueryStringUnique: function (nameProperty, dataObject) {
        var QueryString = '?' + nameProperty + '=' + JSON.stringify(dataObject);
        return QueryString;
    },

    // convertStringToArray: function (cadena) {
    //     const array = cadena.split(",");
    //     return array;
    // },

    // convertArrayToString: function (array) {
    //     const cadena = "";
    //     for (var i = 0; i < array.lenght; i++) {
    //         cadena += array[i].descripcion.join(",");
    //     }
    //     return cadena;
    // },
    // /* casos con RadioButton*/
    // getId: function (arreglo, propierty) {
    //     const row = arreglo.filter((item, index) => {
    //         if (item.data.value === true) return item;
    //     })[0];
    //     return row[propierty];
    // },
    /* casos con CheckBox*/
    getRows: function (arreglo) {
        return arreglo.filter((item, index) => {
            if (item.value === true) return item;
        });
    },

    // getInputs: function (arreglo) {
    //     return arreglo.filter((item, index) => {
    //         if (item.data.descripcion !== null && item.data.descripcion !== "") return item;
    //     });
    // }
    // ,
    // look: function () {
    //     _isLook = true;
    // },
    // unlook: function () {
    //     alert();
    //     _isLook = false;
    // },
    // getLook: function () {
    //     return _isLook;
    // }

    completaCaracteres: function (dato, num) {
        if (dato == null) {
            return '';
        }
        if (dato.length < num) {
            while (dato.length < num) {
                dato = '0' + dato;
            }
        }
        return dato;
    },
    fechaActual: function () {

        var fecha = new Date(); //Fecha actual
        var mes = fecha.getMonth() + 1; //obteniendo mes
        var dia = fecha.getDate(); //obteniendo dia
        var ano = fecha.getFullYear(); //obteniendo año
        if (dia < 10) dia = '0' + dia; //agrega cero si el menor de 10
        if (mes < 10) mes = '0' + mes; //agrega cero si el menor de 10

        if (document.getElementById('fechaEmision')) document.getElementById('fechaEmision').value = ano + '-' + mes + '-' + dia;
        if (document.getElementById('fechaRegistro')) document.getElementById('fechaRegistro').value = ano + '-' + mes + '-' + dia;
    },

    stringToBoolean: function (stringValue) {
        switch (stringValue?.toLowerCase()?.trim()) {
            case 'true':
            case 'yes':
            case '1':
            case true:
            case 1:
                return true;

            case 'false':
            case 'no':
            case '0':
            case null:
            case undefined:
            case false:
            case 0:
                return false;

            default:
                return JSON.parse(stringValue);
        }
    },
    alertNotity: function (titleNotify) {
        let newTitle = titleNotify == true ? "👇️ | Alertas" : "Compras";
        document.title = newTitle;
    }

};
export default Resource;
